import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entries", "pagination"]

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    this.paginationTarget.innerHTML = '<div class="text-center"><i class="fas fa-spin fa-2x fa-spinner"></i></div>' + this.paginationTarget.innerHTML

    fetch(url, {
      headers: { accept: 'application/json'}
    })
    .then((response) => response.json())
    .then(data => {
      this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
      this.paginationTarget.innerHTML = data.pagination
    })
  }
}
