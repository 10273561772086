import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'barcode', 'output', 'outputName' ]

  static values = { guid: String, url: String, prefix: String }

  initialize () {
    this.debug = false
  }

  connect () {
    if(this.debug) { console.log("Connect Scanner: " + this.guidValue) }
    this.reset()
  }

  disconnect () {
    if(this.debug) { console.log("Disconnect Scanner: " + this.guidValue) }
  }

  reset() {
    if(this.debug) { console.log("reset()") }

    //this.enableScan() // uitgeschakeld, altijd door kunnen blijven scannen
    this.outputTarget.textContent = 'Klaar om te scannen!'
    this.outputTarget.className = ''
    this.outputNameTarget.textContent = 'Scan de QR-code'
    this.focus() 
  }

  focus() {
    if(this.debug) { console.log("focus()") }

    this.barcodeTarget.focus()
  }

  enableScan() {
    if(this.debug) { console.log("enableScan()") }
    this.barcodeTarget.disabled = false
  }
  
  disableScan() {
    if(this.debug) { console.log("disableScan()") }
    this.barcodeTarget.disabled = true
  }

  clearScan() {
    if(this.debug) { console.log("clearScan()") }
    this.barcodeTarget.value = ''
    if(this.debug) { this.barcodeTarget.value = 'QRzwembaddewaterlelie-210256901' }
  }

  waiting() {
    if(this.debug) { console.log("waiting()") }

    //this.disableScan() // uitgeschakeld, altijd door kunnen blijven scannen
    this.outputTarget.textContent = 'Barcode gescand!'
    this.outputNameTarget.textContent = 'Even geduld a.u.b.'
    this.outputTarget.className = 'scan-waiting'
  }

  scanWithBarcodeScanner(event) {
    if (event.keyCode == 13) {
      this.waiting()
      this.scan()
    }
  }

  scan() {
    let barcode = this.barcodeTarget.value

    this.clearScan()

    if (!barcode.startsWith(this.prefixValue)) {
      if(this.debug) { console.log("invalid barcode '"+barcode+"', no prefix: " + this.prefixValue) }
      this.reset()
      return false
    }
  
    if(this.debug) { console.log("scan()") }
    if(this.debug) { console.log("prefix: " + this.prefixValue) }
    if(this.debug) { console.log("barcode: " + barcode) }
    if(this.debug) { console.log("fetch: " + this.urlValue+barcode) }

    if (barcode.startsWith(this.prefixValue)) {
      fetch(this.urlValue+barcode)
      .then(response => {
        if(this.debug) { console.log(response) }
        if (!response.ok) {
          throw new Error("Scan Error - er is een probleem, waardoor deze barcode niet gescand kan worden!")
        }
        return response.json()
      })
      .then(json => {
        if(this.debug) { console.log(json) }
        this.outputTarget.textContent = json.message
        this.outputTarget.className = json.classname

        if (json.ticket_type === "subscription") {
          this.outputNameTarget.textContent = json.subscription_id + ": " + json.name
        } else if (json.ticket_type === "ticket") {
          this.outputNameTarget.textContent = json.ticket_id + ": " + json.name
        }
      
        setTimeout(() => {
          this.reset()
        }, 2000)
      })
      .catch((error) => {
        if(this.debug) { console.log(error) }

        this.outputTarget.textContent = error
        this.outputTarget.className = 'scan-error'
        this.outputNameTarget.textContent = 'Error'
        setTimeout(() => {
          this.reset()
        }, 2000)
      })
    } else {
      if(this.debug) { console.log("invalid barcode '"+barcode+"', no prefix: " + this.prefixValue) }
      this.reset()
    }
  }
}
