import { Controller } from "@hotwired/stimulus"
import { Toast } from 'bootstrap';


export default class extends Controller {
  static targets = ["object"]

  connect() {
  }

  render(event) {
    event.preventDefault()
    event.stopPropagation()

    const [, , xhr] = event.detail;
    let jsonResponse = JSON.parse(xhr.responseText)

    if (jsonResponse) {
      this.objectTarget.innerHTML = jsonResponse.plant
      if (jsonResponse.toast) {
        this.toast(jsonResponse.toast)
      }
    }
  }

  toast(message) {
    const element = new DOMParser().parseFromString(message, 'text/html').body.childNodes[0]
    if (element) {
      const toastContainer = document.getElementById('toast')
      if (toastContainer) {
        toastContainer.appendChild(element);
        const toast = new Toast(element)
        toast.show()
      }
    }
  }
}
