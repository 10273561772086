// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("@hotwired/turbo-rails")
require("bootstrap")


import {Tab, Tooltip, Popover, Toast} from 'bootstrap'

document.addEventListener("turbo:load", () => {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })

  let hash = window.location.hash;
  if (hash) {
    let tabEl = document.querySelector('.nav-tabs .nav-item a.nav-link[href="' + hash + '"]')
    if (tabEl) {
      let tab = new Tab(tabEl)
      tab.show();
    }
  }
  document.querySelectorAll('.nav-tabs .nav-item a.nav-link').forEach(item => {
    item.addEventListener('click', event => {
      console.log(event.target.hash)
      window.location.hash = event.target.hash;
    })
  });
})

import "controllers"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
